import { type GetStaticPropsResult } from 'next'
import dynamic from 'next/dynamic'
import Error from 'next/error'

import { errorPageModuleQuery } from '@data/sanity/queries/page'
import { type SanityErrorPage } from '@data/sanity/queries/types/page'
import { type SanitySiteFragment } from '@data/sanity/queries/types/site'
import { defaultModuleQueryParameters, getErrorPage } from '@lib/sanity/page'
import { defaultLocale } from '@lib/language'
import {
  type BasePageProps,
  getNonNullValues,
  getPagePropsFromContext,
  getPageSanityClient,
} from '@lib/page'
import { type CustomErrorStaticPropsContext } from '@lib/routes'

import Layout from '@components/layout'
import Modules from '@modules/modules'
const PreviewModules = dynamic(() => import('@modules/preview-modules'))

type ErrorPageProps = BasePageProps & {
  page: SanityErrorPage | null
  site: SanitySiteFragment | null
}

export const NotFoundPage = ({
  draftMode,
  locale,
  page,
  site,
}: ErrorPageProps) => {
  if (!page || !site) {
    return (
      <Error
        title={`"Error Page (404)" is not set in Sanity, or the page data is missing`}
        statusCode={404}
      />
    )
  }

  return (
    <Layout page={page} site={site} draftMode={draftMode}>
      {!draftMode && <Modules modules={page.modules} />}
      {draftMode && (
        <PreviewModules
          modules={page.modules}
          query={errorPageModuleQuery}
          params={{
            ...defaultModuleQueryParameters,
            locale,
          }}
        />
      )}
    </Layout>
  )
}

export async function getStaticProps(
  context: CustomErrorStaticPropsContext,
): Promise<GetStaticPropsResult<ErrorPageProps>> {
  const sanityClient = getPageSanityClient(!!context.draftMode)
  const errorPage = await getErrorPage(
    sanityClient,
    context.locale ?? defaultLocale,
  )

  return {
    props: {
      ...getPagePropsFromContext(context),
      page: getNonNullValues(errorPage.page),
      site: getNonNullValues(errorPage.site),
    },
    revalidate: 60,
  }
}

export default NotFoundPage
